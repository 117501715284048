<template>
  <b-modal
      id="success-modal"
      hide-header
      hide-footer
      @show="onShowModal"
    >
      <div class="body d-flex flex-column" align="center">
        <b-row class="d-flex">
          <img
            class="icon"
            :src="require('@/assets/images/check-modal.png')"
          />
          <h6
            style="
              color: #525c7a;
              font-weight: 600;
              font-size: 18px;
              line-height: 28px;
              margin: 12px 10px;
            "
          >
            {{ message }}
          </h6>
        </b-row>
      </div>
    </b-modal>
</template>
<script>
export default {
  name: 'success-modal',
  props: {
    message: String,
  },
  methods: {
    async onShowModal(bvModalEvent) {
      await this.timer(200);
      if (!this.message) {
        bvModalEvent.preventDefault();
        return;
      }
      await this.timer(2000);
      this.$bvModal.hide('success-modal');
    },
    timer(interval) {
      return new Promise((resolve, reject) => setTimeout(resolve, interval))
    }
  }
}
</script>
<style lang="scss">
#success-modal {
  .modal-dialog {
    display: flex;
    justify-content: center;
  }
  .modal-content {
    max-width: 600px;
    width: max-content;
    height: auto;

    .modal-body {
      padding: 0 !important;

      .body {
        padding: 10px 20px;
        align-items: center;
        height: 75px;

        .icon {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
</style>
